export const dynamicallyLoadScript = (
	url = "",
	callback = () => {},
	onErrorCallback = () => {}
) => {
	if (typeof url !== "string") return new Error("URL must be a string");
	if (typeof callback !== "function")
		return new Error("Callback must be a function");
	if (typeof onErrorCallback !== "function") return new Error("onErrorCallback must be a function");

	const script = document.createElement("script");
	script.type = "text/javascript";
	if (script.readyState) {
		// IE 9 or below
		script.onreadystatechange = () => {
			if (["loaded", "complete"].includes(script.readyState)) {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {
		script.onload = callback;
		script.onerror = (event, source, lineNumber, columnNumber, error) => {
			onErrorCallback(event, source, lineNumber, columnNumber, error);
		};
	}
	script.src = url;
	document.getElementsByTagName("head")[0].appendChild(script);
};
