import AdSwiper from './AdSwiper';
import AdToggleContent from './AdToggleContent';
import AdTabbedContent from './AdTabbedContent';
import AdForm from './AdForm';
import AdCollapse from './AdCollapse';
import AdAccordion from './AdAccordion';
import AdSpotlight from './AdSpotlight';
import AdVideoPopup from './AdVideoPopup';
import AdPopup from './AdPopup';
import AdOverviewDegreeCards from './AdOverviewDegreeCards';
import AdProgramSearch from './AdProgramSearch';
import AdLink from './AdLink';
import AdCnmForm from './AdCnmForm';
import { dynamicallyLoadScript } from './dynamicallyLoadScript';
import { getCookie } from './cookieHelper';

$(document).ready(() => {

    // Back To Top
    $('#backToTop').on('click', function () {
        const _this = $(this);
        $("html, body").stop(true, true);
        $("html, body").animate({ scrollTop: 0 }, 350, 'linear', () => {
            $(_this).blur();
        });
    }).on('mouseenter focus', function () {
        $(this).addClass('opacity-100');
    }).on('mouseleave blur', function () {
        $(this).removeClass('opacity-100');
    });

    $('#backToTop').on('focus', function () {
        const _this = $(this);
        $(this).bind('keyup.BackToTop', (event) => {
            if (event.which === 13) {
                $(_this).trigger('click');
            }
        });
    });

    $('#backToTop').on('blur', function () {
        $(this).unbind('keyup.BackToTop');
    });

    $('#backToTop').on('mouseleave', function () {
        $(this).blur();
    });

    // the expectation is to only ever have one per page,
    // but in case that changes in the future, this should work all the same,
    // because a GUID is generated for each instance in the BE.
    // we don't need to worry about the fact that "use" is possibly called multiple times,
    // because lightning.out.js behaves similarly to a singleton
    $(".byep-block").each(function(){
        const block = $(this);
        const endpointURL = block.data('url');
        if(!endpointURL) return console.error('BYEP block does not have a URL.');
        // take the endpointURL,append /lightning/lightning.out.js
        const scriptURL = endpointURL.endsWith('/')
        ? `${endpointURL}lightning/lightning.out.js`
        : `${endpointURL}/lightning/lightning.out.js`;

        // I'm declaring this function inside the iteration block
        // because it's not clear to me whether these params in the future might stem from
        // meta-data attached to the block itself as opposed to just taking from the URL
        const getBYEPParams = (urlParams) => {
            const RAW_VENDOR_ID = block.data("vendor-id");
            // I don't know where this magic value "4171900" comes from, but I borrowed the same logic
            // that is used in the backend at EncryptedCookieService.cs
            // for each value, we'll try and pick from the cookie first, then the URL
            // some keys start in uppercase on purpose to match the values in SiteConstants.
            const RAW_TRACKING_COMM_CODE = block.data("comm-code");
            const CITY = block.data("city");
            const STATE = block.data("state");
            const POSTAL_CODE = block.data("postal-code");
            const COUNTRY = block.data("country");
            const UTM_SOURCE = block.data("utm-source");
            const UTM_CAMPAIGN = block.data("utm-campaign");
            const UTM_AD_GROUP = block.data("utm-ad-group");
            const UTM_KEYWORDS = block.data("utm-keywords");
            const UTM_CHANNEL = block.data("utm-channel");
            const DOUBLECLICK_ID = block.data("doubleclick-id");
            const CID = block.data("cid");
            const IP_ADDRESS = block.data("ip");

            const USER_DEVICE_TYPE = block.data("user-device-type");

            const UTM_MEDIUM = block.data("utm-medium");
            const SESSION_ID = block.data("session-id");
            const GCLID = block.data("gclid");
            const FBCLID = block.data("fbclid");
            const FIRST_PAGE_URL = block.data("first-page-url");
            const GTM_REFERRER = block.data("gtm-referrer");
            const MSCLKID = block.data("msclkid");
            const LI_FAT_ID = block.data("li-fat-id");
            const CONVERTED_PAGE_URL = block.data("converted-page-url");

            return {
                raw_vendor_id: RAW_VENDOR_ID,
                raw_tracking_comm_code: RAW_TRACKING_COMM_CODE,
                city: CITY,
                state: STATE,
                postal_code: POSTAL_CODE,
                country: COUNTRY,
                utm_source: UTM_SOURCE,
                utm_campaign: UTM_CAMPAIGN,
                utm_ad_group: UTM_AD_GROUP,
                utm_keywords: UTM_KEYWORDS,
                utm_channel: UTM_CHANNEL,
                doubleclick_id: DOUBLECLICK_ID,
                cid: CID,
                ip_address: IP_ADDRESS,
                user_device_type: USER_DEVICE_TYPE,
                utm_medium: UTM_MEDIUM,
                session_id: SESSION_ID,
                gclid: GCLID,
                fbclid: FBCLID,
                Client_User_Agent__c: navigator.userAgent,
                First_page_url: FIRST_PAGE_URL,
                Visitor_hostname: GTM_REFERRER,
                msclkid: MSCLKID,
                device_type: USER_DEVICE_TYPE,
                li_fat_id: LI_FAT_ID,
                converted_page_url: CONVERTED_PAGE_URL,
                client_user_agent: navigator.userAgent,
            }
        }
        
        const targetElement = block.find(".byep-block-content-container").get(0);
        if(!targetElement) return console.error('BYEP block does not have a target element.');
        const targetElementID = targetElement.id;

        const states = ["loading", "loaded", "error"];
        const setState = (state) => {
            if(!states.includes(state)) return console.error(`Invalid state: ${state}`);
            for(const s of states) {
                block.toggleClass(s, s === state);
            }
            if(state === "loading") {
                targetElement.innerHTML = "";
                const spinner = document.createElement("div");
                spinner.classList.add("spinner-border");
                spinner.setAttribute("role", "status");
                spinner.innerHTML = `<span class="sr-only">Loading...</span>`;
                targetElement.appendChild(spinner);
            }else if(state === "error") {
                targetElement.innerHTML = "";
                const alert = document.createElement("div");
                alert.classList.add("alert");
                alert.classList.add("alert-danger");
                alert.setAttribute("role", "alert");
                alert.innerHTML = "<strong>Error:</strong> There was an error loading the content.";
                targetElement.appendChild(alert);
            }else{
                block.find(".spinner-border").each(function(){$(this).remove();});
                block.find(".alert").each(function(){$(this).remove();});
            }
        };
        setState("loading");
        let urlParams = {};
        try{
            // this constructor can throw.
            urlParams = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
        }catch(err){
            console.error('BYEP: Error parsing URL parameters', err);
            setState("error");
            return;
        }
        dynamicallyLoadScript(scriptURL, ()=> {
            const lightning = window.$Lightning;
            if(typeof lightning !== "object") {
                setState("error");
                return console.error('BYEP script loaded but $Lightning could not be defined.');
            }

            // this is not really catcheable when it fails,
            // and it doesn't seem like it accepts a callback argument to detect it either.
            lightning.use('c:byepApp', () => {
                const byepParams = getBYEPParams(urlParams);
                lightning.createComponent(
                    'c:byepMain',
                    {
                        debug: urlParams.debug === "true",
                        uniqueKey: urlParams.uniqueKey === null ? '' : urlParams.uniqueKey,
                        params: byepParams
                    },
                    targetElementID,
                    () => {
                        setState("loaded");
                    }
                );
               // same URL used in the script tag?
            }, endpointURL);
        }, (event, source, lineNumber, columnNumber, error)=>{
            // we'll prioritize whatever is given at face value from the callback
            // otherwise, we'll try to find it from the error also from the callback
            // otherwise, we'll try to find it from the event if it's an ErrorEvent
            const erroredURL = scriptURL;
            const canonLineNumber = Number.isFinite(lineNumber) ? lineNumber : error !== undefined && error instanceof ErrorEvent && Number.isFinite(error.lineno) ? error.lineno : event !== undefined && event instanceof ErrorEvent && Number.isFinite(event.lineno) ? event.lineno : null;
            const canonColumnNumber = Number.isFinite(columnNumber) ? columnNumber : error !== undefined && error instanceof ErrorEvent && Number.isFinite(error.colno) ? error.colno : event !== undefined && event instanceof ErrorEvent && Number.isFinite(event.colno) ? event.colno : null;
            const canonStack = error !== undefined && error instanceof Error && error.stack ? error.stack : event !== undefined && event instanceof Error && event.stack ? event.stack : null;
            const canonMessage = error !== undefined && error instanceof Error ? error.message : event !== undefined ? typeof event === "string" ? event : event instanceof Error ? event.message : null : null;

            console.error(`Error loading BYEP script from given url:"${erroredURL}":
                ${erroredURL ? `URL: ${erroredURL}` : ""}
                ${canonMessage ? `Message: ${canonMessage}` : ""}
                ${typeof source === "string" ? `Source: ${source}` : ""}
                ${canonLineNumber ? `Line Number: ${canonLineNumber}` : ""}
                ${canonColumnNumber ? `Column Number: ${canonColumnNumber}` : ""}
                ${canonStack ? `Stack: ${canonStack}` : ""}
                ${error instanceof Error ? `CallbackError: ${error}` : ""}
                ${event instanceof ErrorEvent ? `CallbackEventError: ${event}` : ""}
            `);
            setState("error");
        });
    });
    // for all anchors, if the href is present and starts with #
    // and the nav is currently sticking, we'll prevent the default behavior
    // and instead scroll to the target element with the same ID
    // minus the visible height of the nav
    $("a[href^='#']").on('click', function(event){
        const anchor = $(this);
        const target = $(anchor.attr('href'));
        if(target.length !== 1) return;
        const header = $('#header');
        if(header.length !== 1) return;
        // if the header's style does not have sticky position, return too
        if(header.css('position') !== 'sticky') return;
        event.preventDefault();

        const headerHeight = header.outerHeight(true);
        const targetOffset = target.offset().top - headerHeight;
        const targetScrollMarginTopPX = target.css('scroll-margin-top');
        const targetScrollMarginTop = targetScrollMarginTopPX === "0px" ? 0 : Number.parseInt(targetScrollMarginTopPX);

        $("html, body").stop(true, true);
        $("html, body").animate({ scrollTop: targetOffset - targetScrollMarginTop }, 350, 'linear', () => {
            anchor.blur();
        });
    })
});

customElements.define('ad-swiper', AdSwiper);
customElements.define('ad-form', AdForm);
customElements.define('ad-toggle-content', AdToggleContent);
customElements.define('ad-tabbed-content', AdTabbedContent);
customElements.define('ad-accordion', AdAccordion);
customElements.define('ad-collapse', AdCollapse);
customElements.define('ad-spotlight', AdSpotlight);
customElements.define('ad-video-popup', AdVideoPopup);
customElements.define('ad-popup', AdPopup);
customElements.define('ad-overview-degree-cards', AdOverviewDegreeCards);
customElements.define('ad-program-search', AdProgramSearch);
customElements.define('ad-link', AdLink);
customElements.define('ad-cnm-form', AdCnmForm);